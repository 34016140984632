import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { fontLight, theme } from '../util/style'

const PageHeading = ({ children, color = theme.colors.grey7 }) => (
  <Wrap color={color}>{children}</Wrap>
)

export default PageHeading

const Wrap = styled.div`
  text-align: left;
  ${fontLight()}
  color: ${({ color }) => color};
  font-size: 30px;
  line-height: 130%;
  @media (max-width: 800px) {
    font-size: 24px;
    margin-bottom: 1.5rem;
  }
  p {
    margin-top: 0%;
    margin-bottom: 2em;
    width: 100%;
  }
  a {
    border-bottom: 1px solid ${theme.colors.grey11};
    &:hover {
      color: ${theme.colors.grey1};
      border-bottom: 1px solid ${theme.colors.grey2};
    }
  }
`
