import { Box } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../../util/style'
import BlogRow from '../BlogRow'

const BlogTitleRow = ({ data }) => {
  const { title } = data || {}

  if (!title) return null

  return <Wrapper font={data?.font}>{title}</Wrapper>
}

export default BlogTitleRow

const Wrapper = styled(Box)`
  font-size: clamp(50px, 8vw, 70px);
  font-family: ${({ font }) => font || theme.font.fontFamilyLight};
  text-align: center;

  line-height: 100%;

  @media (max-width: 800px) {
    font-size: 37px;
  }
`
