import React from 'react'
import { Flex, Box } from '@rebass/grid'
import PageHeading from '../../../page-heading'
import PageDescription from '../../../page-description'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const BlogOverviewRow = ({ data }) => {
  const { heading, body } = data
  return (
    <Box px={2}>
      <Flex
        flexWrap={['wrap', 'nowrap']}
        style={{ borderTop: '1px solid #888888' }}
        pt={[2, 3]}
      >
        <Box width={[1, 6 / 12]}>
          <Box width="90%">
            <PageHeading>{heading}</PageHeading>
          </Box>
        </Box>
        <Box width={[1, 6 / 12]} mr={[0, 0]} px={[0, 2]}>
          <PageDescription align="left">{renderRichText(body)}</PageDescription>
        </Box>
      </Flex>
    </Box>
  )
}
export default BlogOverviewRow
