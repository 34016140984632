import React from 'react'
import BlogRow from '../BlogRow'
import BlogMedia from '../BlogMedia'
import { Box } from '@rebass/grid'

const BlogMediaRowCols = ({ data }) => {
  const { media1, media2, media1Caption, media2Caption } = data

  return (
    <BlogRow data={{ type: 'half' }}>
      <BlogMedia
        data={{
          caption: media1Caption?.media1Caption,
          width: 5 / 6,
          image: media1,
          alignItems: 'center',
        }}
      />
      <Box mt={[3, 0]}>
        <BlogMedia
          data={{
            caption: media2Caption?.media2Caption,
            width: 1,
            image: media2,
          }}
        />
      </Box>
    </BlogRow>
  )
}

export default BlogMediaRowCols
